<script setup>
import { provide } from 'vue'
import { RouterView } from 'vue-router'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
function isIPhone() {
    return /webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function adjustViewportHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener('resize', adjustViewportHeight);
adjustViewportHeight();


// console.log("🚀 ~ isMobile():\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", isMobile())
// if (isMobile()) {
//     var html = document.getElementsByTagName("html");

//     var style = window.getComputedStyle(html[0]);
//     var fontSize = style.getPropertyValue('font-size');

//     // html[0].style.fontSize = (parseFloat(fontSize) * 1.75) + "px"; // 1.75
//     // router.push('/mobileUnsupported');
// }

const originalFetch = window.fetch;

window.fetch = async (input, init = {}, overrideTokenCheck = false) => {
    if (!init.method || init.method === 'GET') {
        init.method = 'POST';
    }

    return new Promise((resolve, reject) => {
        originalFetch(input, init).then(org => {
            if (route.meta.requiresAuth && !document.cookie.includes('appToken') && !overrideTokenCheck) {
                return router.push({
                    path: '/login',
                    query: { redirect: route.fullPath },
                }).then(() => reject(new Error('User is not logged in')))
            }

            resolve(org);
        }).catch(reject);
    })
};

provide('isMobile', isMobile);
provide('isIPhone', isIPhone);

</script>
<template>
    <div :class="{ 'min-w-[932px]': !isMobile() }"
        class="bg-gray-50 dark:bg-slate-800 w-full flex flex-col max-h-[100vh]"
        style="height: calc(var(--vh) * 100);">
        <RouterView />
    </div>
</template>
